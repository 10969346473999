import { FC, useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import {
  MenuExpandableContainer,
  MenuItemWrapper,
  StyledMenu,
} from './menu.styles'
import { MenuNode, ThemeVariant } from 'types'
import {
  Box,
  Text,
  Hidden,
  Icon as IconComponent,
  Anchor,
  ALL_FIGURES,
  FigureType,
  Figure,
  ALL_ICONS,
  IconType,
} from 'ui/core'
import { MenuItem } from './menuItem'
import { MobileMenu } from './mobileMenu/mobileMenu'
import { ThemeColorProp } from 'ui/theme'

interface Props {
  nav: MenuNode[]
  open: boolean
  theme: ThemeVariant
  toggleContentMenu: () => void
  toggleUserMenu: () => void
  isLoggedIn: boolean
  userName: string
}

export const Menu: FC<Props> = ({
  open,
  nav,
  theme,
  isLoggedIn,
  userName,
  toggleContentMenu,
  toggleUserMenu,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showScroll, setShowScroll] = useState<boolean>(true)
  const [activeMenu, setActiveMenu] = useState<MenuNode | null>(
    nav && nav.length > 0 ? nav[0] : null,
  )
  const [height, setHeight] = useState<string | number>(0)

  useEffect(() => {
    if (!open && height !== 0) setHeight(0)
    else if (open && height === 0) setHeight('auto')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <StyledMenu
      position="absolute"
      top={0}
      left={0}
      zIndex="-1"
      width="full"
      bg="white"
      boxShadow="menu"
      autoFocus={open ? true : false}
    >
      <AnimateHeight duration={400} height={height}>
        <Hidden below="lg" autoFocus={open ? true : false}>
          <Box
            pt={12}
            pb={6}
            ref={ref}
            autoFocus={open ? true : false}
            display="flex"
            justifyContent="flex-start"
            height="viewportHeight"
            overflow="scroll"
            onScroll={(event) => {
              if (event.currentTarget.scrollTop >= 60) {
                setShowScroll(false)
              } else if (
                event.currentTarget.scrollTop < 60 &&
                showScroll === false
              ) {
                setShowScroll(true)
              }
            }}
          >
            <Box
              display="flex"
              width="3/12"
              pr={[0, 0, 0, 2, 2, 4]}
              flexDirection="column"
              justifyContent="flex-start"
              height="viewportHeight"
            >
              {nav.map((menu, index) => (
                <MenuNodeItem
                  {...menu}
                  key={index}
                  isActive={activeMenu?.Name === menu.Name}
                  setActiveMenu={() => setActiveMenu(menu)}
                />
              ))}
              <Box height="full">
                <MenuNodeItem
                  noHover
                  hideArrow
                  Name={
                    theme === ThemeVariant.HUSA ? 'Blómaval' : 'Húsasmiðjan'
                  }
                  Url={theme === ThemeVariant.HUSA ? '/blomaval' : '/'}
                  Icon={theme === ThemeVariant.HUSA ? 'logoBloma' : 'logoHusa'}
                  isActive={false}
                  logoSize={40}
                  color={theme === ThemeVariant.HUSA ? 'bloma' : undefined}
                />
              </Box>
            </Box>
            <Box
              width="9/12"
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              height="viewportHeight"
            >
              {activeMenu && (
                <MenuItem
                  menuRef={ref}
                  menu={activeMenu}
                  key={activeMenu.Name}
                  hasScrolled={showScroll}
                />
              )}
            </Box>
          </Box>
        </Hidden>
        <Hidden above="lg">
          <MobileMenu
            menus={nav}
            theme={theme}
            toggleContentMenu={toggleContentMenu}
            toggleUserMenu={toggleUserMenu}
            isLoggedIn={isLoggedIn}
            userName={userName}
          />
        </Hidden>
      </AnimateHeight>
    </StyledMenu>
  )
}

export const MenuNodeItem: FC<
  MenuNode & {
    isActive?: boolean
    noHover?: boolean
    hideArrow?: boolean
    setActiveMenu?: () => void
    logoSize?: string | number
    color?: ThemeColorProp
  }
> = ({
  Name,
  Icon,
  Url,
  isActive,
  setActiveMenu,
  logoSize,
  noHover,
  hideArrow,
  color,
}) => (
  <MenuItemWrapper
    active={isActive}
    noHover={noHover}
    width="full"
    display="flex"
    justifyContent="flex-start"
    alignItems="center"
    px={1}
    pb={1}
    onMouseEnter={() => {
      if (setActiveMenu) setActiveMenu()
    }}
  >
    <MenuExpandableContainer>
      <Box
        mx={[0, 0, 0, 1, 2, 1]}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          width="full"
          alignItems="center"
        >
          {ALL_FIGURES.includes(Icon as FigureType) ? (
            <Figure
              type={
                Icon && ALL_FIGURES.includes(Icon as FigureType)
                  ? (Icon as FigureType)
                  : 'logoBloma'
              }
              color={color || 'gray200'}
              fill={color || undefined}
              size={logoSize || '24'}
            />
          ) : ALL_ICONS.includes(Icon as IconType) ? (
            <IconComponent
              type={Icon as IconType}
              color={color || 'gray200'}
              size="24"
            />
          ) : (
            <Figure
              type="verkfaeri"
              color={color || 'gray200'}
              fill={color || undefined}
              size={logoSize || '24'}
            />
          )}
          <Anchor href={Url}>
            <Text
              mb={0}
              pl={[1, 1, 1, 3, 2, 4]}
              color={color ? color : isActive ? 'primary300' : 'dark400'}
              fontWeight={isActive ? 'bold' : 'medium'}
            >
              {Name}
            </Text>
          </Anchor>
        </Box>
        <Box
          pr={4}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          {!hideArrow && (
            <IconComponent type="arrowRight" color="primary300" size="18" />
          )}
        </Box>
      </Box>
    </MenuExpandableContainer>
  </MenuItemWrapper>
)
